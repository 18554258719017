$white:    #fff !default;
$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #999 !default;
$gray-600: #7A8288 !default;
$gray-700: #52575C !default;
$gray-800: #3A3F44 !default;
$gray-900: #272B30 !default;
$black:    #000 !default;

$blue:    #007bff !default;
$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$red:     #ee5f5b !default;
$orange:  #fd7e14 !default;
$yellow:  #f89406 !default;
$green:   #62c462 !default;
$teal:    #20c997 !default;
$cyan:    #5bc0de !default;

$primary:       $gray-800 !default;
$secondary:     $gray-600 !default;
$success:       $green !default;
$info:          $cyan !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-200 !default;
$dark:          $gray-900 !default;

///////////
// slate //
///////////
@import '/css/slate-4-3-1.min.css';

////////////
// layout //
////////////
// override h1 margin
h1 {
	margin-bottom: 1.5rem;
}

// add some space above/below content
main.container {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

// parallax
.parallax-window {
  min-height: 240px;
  background: transparent;
  margin-bottom: 3rem;
}

// staff profiles
.staff-profile {
	display: flex;
	flex-flow: row;
	align-items: center;
	margin-bottom: 2rem;

	.staff-image {
    text-align: center;
  }

  .staff-image-default {
    font-size: 80px;
  }
}

// flex helpers
.flex-center {
  align-items: center;
}

// sticky list groups
// or anything else that needs to stick
.sticky {
	position: -webkit-sticky;
	position: sticky;
	top: 1rem;
}

//////////
// home //
//////////
.home-lead {
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.home-row {
  margin-top: 2rem;
  margin-bottom: 2rem;;
}

///////////
// forms //
///////////
.form-document {
  display: flex;
  flex-flow: column;
  padding: 1.5rem;
  main {
    display: flex;
    flex-flow: row;
    align-items: center;
    img {
      width: 85px;
      height: 110px;
      margin-right: 1rem;
    }
    div {
      align-items: center;
    }
  }
}

//////////////////////////
// social share buttons //
//////////////////////////
.social-buttons {
	margin: 2rem 0;
}
.twitter-share-button {
  display: inline-block;
  vertical-align: bottom;
}
.twitter-share-button {
  margin-left: 1rem;
}

////////////
// footer //
////////////
footer.footer {
  margin-top: 4em;
	margin-bottom: 2em;

	.row {
		margin-bottom: 2rem;
  }
  
  .footer-links {
    font-size: 1.75rem;
    a {
      margin: 0 0.25rem;
    }
    .fa-facebook-square {
      color: $blue;
    }
    .fa-rss {
      color: $orange;
    }
  }

	.row div {
		align-items: center;
		text-align: center;
	}
	
  .copyright {
    text-align: center;
		margin-bottom: 1rem;
		font-size: 0.9rem;
	}
	.fa-coffee {
		color: #be9969;
	}
}

/////////
// 404 //
/////////
.not-found {
	margin: 3rem 0;
	text-align: center;

	h1 {
		font-size: 3rem;
	}
}

/*  Bootstrap Clearfix */
/*  Tablet  */
@media (min-width:767px){
  /* Column clear fix */
  .col-lg-1:nth-child(12n+1),
  .col-lg-2:nth-child(6n+1),
  .col-lg-3:nth-child(4n+1),
  .col-lg-4:nth-child(3n+1),
  .col-lg-6:nth-child(2n+1),
  .col-md-1:nth-child(12n+1),
  .col-md-2:nth-child(6n+1),
  .col-md-3:nth-child(4n+1),
  .col-md-4:nth-child(3n+1),
  .col-md-6:nth-child(2n+1){
    clear: none;
  }
  .col-sm-1:nth-child(12n+1),
  .col-sm-2:nth-child(6n+1),
  .col-sm-3:nth-child(4n+1),
  .col-sm-4:nth-child(3n+1),
  .col-sm-6:nth-child(2n+1){
    clear: left;
  }
}
/*  Medium Desktop  */
@media (min-width:992px){
  /* Column clear fix */
  .col-lg-1:nth-child(12n+1),
  .col-lg-2:nth-child(6n+1),
  .col-lg-3:nth-child(4n+1),
  .col-lg-4:nth-child(3n+1),
  .col-lg-6:nth-child(2n+1),
  .col-sm-1:nth-child(12n+1),
  .col-sm-2:nth-child(6n+1),
  .col-sm-3:nth-child(4n+1),
  .col-sm-4:nth-child(3n+1),
  .col-sm-6:nth-child(2n+1){
    clear: none;
  }
  .col-md-1:nth-child(12n+1),
  .col-md-2:nth-child(6n+1),
  .col-md-3:nth-child(4n+1),
  .col-md-4:nth-child(3n+1),
  .col-md-6:nth-child(2n+1){
    clear: left;
  }
}
/*  Large Desktop  */
@media (min-width:1200px){
  /* Column clear fix */
  .col-md-1:nth-child(12n+1),
  .col-md-2:nth-child(6n+1),
  .col-md-3:nth-child(4n+1),
  .col-md-4:nth-child(3n+1),
  .col-md-6:nth-child(2n+1),
  .col-sm-1:nth-child(12n+1),
  .col-sm-2:nth-child(6n+1),
  .col-sm-3:nth-child(4n+1),
  .col-sm-4:nth-child(3n+1),
  .col-sm-6:nth-child(2n+1){
    clear: none;
  }
  .col-lg-1:nth-child(12n+1),
  .col-lg-2:nth-child(6n+1),
  .col-lg-3:nth-child(4n+1),
  .col-lg-4:nth-child(3n+1),
  .col-lg-6:nth-child(2n+1){
    clear: left;
  }
}
